import React, { useEffect } from "react";
import "./weather.scss";

const Weather = ({ func }) => {
  useEffect(() => {
    func(5);
  }, []);

  return (
    <>
      <div className="weatherinfo">
        <iframe
          className="weather_table"
          id="tableWidget"
          title="Table widget"
          src="https://www.yr.no/en/content/2-785704/table.html"
        ></iframe>
        {/* <iframe
          className="weather_meteogram"
          id="meteogramWidget"
          title="Meteogram widget"
          src="https://www.yr.no/en/content/2-785704/meteogram.svg"
        ></iframe> */}
      </div>
    </>
  );
};

export default Weather;
