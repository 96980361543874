import React, { useEffect } from "react";

const RestoranInfo = ({ func }) => {
  useEffect(() => {
    func(4);
  }, []);

  return <>OGLAS EDIT</>;
};

export default RestoranInfo;
