import "./reklame.scss";
import reklame from "../../data/reklame.json";

const Reklama2 = () => {
  return (
    <div className="reklame">
      <img src={reklame[1].photos[0]} class="d-block w-100" alt="..."></img>
    </div>
  );
};

export default Reklama2;