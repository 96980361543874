import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./smestaj.scss";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import { BiWifi } from "react-icons/bi";
import { LuParkingCircle } from "react-icons/lu";
import { BsTelephone } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa";
import { TbBrandBooking } from "react-icons/tb";
import { TbWorld } from "react-icons/tb";
import { MdOutlineEmail } from "react-icons/md";
import Map from "../map";

const SmestajInfo = ({ func }) => {
  useEffect(() => {
    func(3);
  }, []);

  const location = useLocation();
  const data = location.state;
  console.log(data);

  return (
    <>
      <div className="container cards infostrana">
        <h1>{data.name}</h1>
        {/* CAROUSEL */}
        <Carousel>
          {data.photos.map((item) => (
            <Carousel.Item>
              <>
                <img src={item} class="d-block w-100" alt="..."></img>
              </>
            </Carousel.Item>
          ))}
        </Carousel>
        {/* CAROUSEL */}
        {data.text.map((item) => (
          <p className="paragraf">{item}</p>
        ))}
        <div className="usluge">
          {data.parking != null && (
            <>
              <LuParkingCircle className="znak" />
              <span>Besplatan parking</span>
              <br></br>
            </>
          )}
          {data.wifi != null && (
            <>
              <BiWifi className="znak" />
              <span>Besplatan Wi-Fi</span>
            </>
          )}
        </div>
        <hr></hr>
        <div className="kontakt-info">
          <h5>Kontakt info:</h5>
          <div className="telefon">
            <BsTelephone className="znak-tel" />
            <span>
              <a href={`tel:${data.contact.phone}`}>{data.contact.phone}</a>
            </span>
          </div>
          <div className="kontakti">
            {data.contact.website != null && (
              <>
                <a href={data.contact.website}>
                  <TbWorld className="znak znak3 znak4" />
                </a>
              </>
            )}
            {data.contact.facebook != null && (
              <>
                <a href={data.contact.facebook}>
                  <FaFacebook className="znak znak3" />
                </a>
              </>
            )}
            {data.contact.instagram != null && (
              <>
                <a href={data.contact.instagram}>
                  <BsInstagram className="znak znak3" />
                </a>
              </>
            )}
            {data.contact.booking != null && (
              <>
                <a href={data.contact.booking}>
                  <TbBrandBooking
                    className="znak2 znak3"
                    style={{
                      position: "relative",
                      transform: "translateY(-2px)",
                    }}
                  />
                </a>
              </>
            )}
            {data.contact.email != null && (
              <>
                <a href={`mailto:${data.contact.email}`}>
                  <MdOutlineEmail
                    className="znak2 znak3"
                    style={{
                      position: "relative",
                      transform: "translateY(-2px)",
                    }}
                  />
                </a>
              </>
            )}
          </div>
        </div>
        <hr></hr>
        <div className="adresa">
          <h5>Adresa:</h5>
          <p>{data.location}</p>
        </div>
        <Map lokacija={data.locationgoogle} />
      </div>
    </>
  );
};

export default SmestajInfo;
