import React, { useEffect } from "react";
import "./oglasi.scss";
import Reklama1 from "../reklame/Reklama1";
import Reklama2 from "../reklame/Reklama2";

const OglasNovi = ({ func }) => {
  useEffect(() => {
    func(2);
  }, []);

  const datum = new Date().getTime();

  return (
    <>
      <Reklama1 />
      <>POSTAVI OGLAS</>
      {/* FORMA ZA FIREBASE ZAPIS */}
      {/* AUTOMATSKI NAVIGATE NA OGLASE */}
      <Reklama2 />
    </>
  );
};

export default OglasNovi;
