import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BiHomeAlt2, BiRestaurant } from "react-icons/bi";
import { FaBed } from "react-icons/fa";
// import { BsBinocularsFill } from "react-icons/bs";
import { FaSearchLocation } from "react-icons/fa";
import { TiWeatherPartlySunny } from "react-icons/ti";
import { TbArrowsRightLeft } from "react-icons/tb";
import { RxFileText } from "react-icons/rx";
import "./header.scss";

const Header = ({ active }) => {
  const navigate = useNavigate();
  const [activeBtn, setActiveBtn] = useState({ activeButton: 1 });

  useEffect(() => {
    setActiveBtn({ ...activeBtn, activeButton: +active });
  }, [active]);

  return (
    <nav className="navigation-bar">
      <button
        className={`${
          activeBtn.activeButton === 1 ? "active" : "nonActive"
        } home-button`}
        onClick={() => {
          setActiveBtn({ ...activeBtn, activeButton: 1 });
          navigate("/");
        }}
      >
        <BiHomeAlt2 />
      </button>
      <button
        className={`${activeBtn.activeButton === 3 ? "active" : "nonActive"}`}
        onClick={() => {
          setActiveBtn({ ...activeBtn, activeButton: 3 });
          navigate("/smestaj");
        }}
      >
        <FaBed />
      </button>
      <button
        className={`${activeBtn.activeButton === 2 ? "active" : "nonActive"}`}
        onClick={() => {
          setActiveBtn({ ...activeBtn, activeButton: 2 });
          navigate("/oglasi");
        }}
      >
        <TbArrowsRightLeft />
      </button>
      <button
        className={`${activeBtn.activeButton === 4 ? "active" : "nonActive"}`}
        onClick={() => {
          setActiveBtn({ ...activeBtn, activeButton: 4 });
          navigate("/restorani");
        }}
      >
        <BiRestaurant />
      </button>
      <button
        className={`${activeBtn.activeButton === 7 ? "active" : "nonActive"}`}
        onClick={() => {
          setActiveBtn({ ...activeBtn, activeButton: 7 });
          navigate("/sightseeing");
        }}
      >
        <FaSearchLocation />
      </button>
      <button
        className={`${activeBtn.activeButton === 5 ? "active" : "nonActive"}`}
        onClick={() => {
          setActiveBtn({ ...activeBtn, activeButton: 5 });
          navigate("/weather");
        }}
      >
        <TiWeatherPartlySunny />
      </button>
      {/* BUTTON ZA VESTI */}
      {/* <button
        className={`${activeBtn.activeButton === 6 ? "active" : "nonActive"}`}
        onClick={() => {
          setActiveBtn({ ...activeBtn, activeButton: 6 });
          navigate("/info");
        }}
      >
        <RxFileText />
      </button> */}
    </nav>
  );
};

export default Header;
