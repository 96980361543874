import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./oglasi.scss";
import oglasi from "../../data/oglasi.json";
import Reklama1 from "../reklame/Reklama1";
import Reklama2 from "../reklame/Reklama2";

const Oglasi = ({ func }) => {
  useEffect(() => {
    func(2);
  }, []);

  const datum = new Date().getTime();

  return (
    <div className="container-oglasi">
      <div className="reklama">
        <Reklama1 />
      </div>
      {/* FORMA ZA LOGIN ZA OGLASE */}
      <div className="oglasi-login">
        <p>Želite da postavite oglas?</p>
        <a href="/user-login">Ulogujte se</a>
      </div>
      {/* <hr /> */}
      {/*  */}

      {/* <div className="cena-oglasavanja">
        <h5>POSTAVITE VAŠ OGLAS</h5>
        <p>Oglasi za slobodna radna mesta su besplatni.</p>
        <p>Ostali oglasi su 250din. mesečno ili 500din. za tri meseca.</p>
        <h6>Kontakt:</h6>
        <div className="mail">
          <a href="mailto:volimsokobanju@gmail.com">volimsokobanju@gmail.com</a>
        </div>
      </div> */}

      <div className="oglasi">
        {oglasi.length > 0 &&
          oglasi.map(
            (item) =>
              // Ako je ( datum < date_end ) PRIKAZI OGLAS
              item.show_ad === true &&
              datum < new Date(item.date_end).getTime() && (
                <Link to="/oglas-info" state={item}>
                  {/* Span tag da bi Link sadrzao content */}
                  <span className="card" style={{ display: "block" }}>
                    <div class="oglas">
                      <img src={item.photos[0]} alt="..."></img>
                      <div className="divright">
                        <h5>{item.title}</h5>
                        <h6 className="cena-cards">{item.cena}</h6>
                        <p>{item.mesto}</p>
                      </div>
                    </div>
                  </span>
                </Link>
              )
          )}
      </div>
      <div className="reklama">
        <Reklama2 />
      </div>
    </div>
  );
};

export default Oglasi;
