import "./reklame.scss";
import reklame from "../../data/reklame.json";

console.log(reklame);

const Reklama1 = () => {
  return (
    <div className="reklame">
      <img src={reklame[0].photos[0]} class="d-block w-100" alt="..."></img>
    </div>
  );
};

export default Reklama1;
