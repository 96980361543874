import React, { useEffect } from "react";

const Info = ({ func }) => {
  useEffect(() => {
    func(6);
  }, []);

  return <>INFORMACIJE</>;
};

export default Info;
