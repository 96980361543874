import React from "react";
import "./map.scss";

const Map = ({ lokacija }) => {
  return (
    <iframe
      src={lokacija}
      style={{
        border: "2px solid #7D9D9C",
        boxShadow: "3px 3px 3px rgb(65, 65, 65)",
        width: "100%",
        // height: "35vh",
      }}
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  );
};

export default Map;
