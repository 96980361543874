import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./user.scss";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";

// import axios from "../../api/axios";
// const LOGIN_URL = "/auth";

const UserLogIn = () => {
  const navigate = useNavigate();

  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  const [passVisible, setPassVisible] = useState(false);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, email, pwd]);

  const passwordVisibility = () => {
    passVisible ? setPassVisible(false) : setPassVisible(true);
  };

  // const handleSubmit = async (e) => {
  const handleSubmit = (e) => {
    e.preventDefault();

    // try {
    //   const response = await axios.post(
    //     LOGIN_URL,
    //     JSON.stringify({ user, pwd }),
    //     {
    //       headers: { "Content-Type": "application/json" },
    //       withCredentials: true,
    //     }
    //   );
    //   console.log(JSON.stringify(response?.data));
    //   //console.log(JSON.stringify(response));
    //   const accessToken = response?.data?.accessToken;
    //   const roles = response?.data?.roles;
    //   setAuth({ user, pwd, roles, accessToken });
    //   setUser("");
    //   setPwd("");
    //   setSuccess(true);
    // } catch (err) {
    //   if (!err?.response) {
    //     setErrMsg("No Server Response");
    //   } else if (err.response?.status === 400) {
    //     setErrMsg("Nedostaje Korisničko ime ili Lozinka");
    //   } else if (err.response?.status === 401) {
    //     setErrMsg("Korisnik ne postoji");
    //   } else {
    //     setErrMsg("Logovanje nije uspelo");
    //   }
    //   errRef.current.focus();
    // }

    signInWithEmailAndPassword(auth, email, pwd)
      .then((userCredential) => {
        // Signed in
        setUser(userCredential.user);
        setSuccess(true);
        console.log(userCredential);
        console.log(userCredential.operationType);
        console.log("user:", user);
        // ...
      })
      .catch((err) => {
        if (!err?.response) {
          setErrMsg("No Server Response");
        } else if (err.response?.status === 400) {
          setErrMsg("Nedostaje Korisničko ime ili Lozinka");
        } else if (err.response?.status === 401) {
          setErrMsg("Korisnik ne postoji");
        } else {
          setErrMsg("Logovanje nije uspelo");
        }
        errRef.current.focus();
      });
  };

  return (
    <>
      {success ? (
        <section>
          {/* <h1>Prijavljeni ste!</h1>
          <br /> */}
          {navigate("/oglas-novi")};
          {/* <p>
            <a href="/oglasi">Idi na oglase</a>
          </p> */}
        </section>
      ) : (
        <section>
          <p
            ref={errRef}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <h1>Prijava na sajt</h1>
          <form onSubmit={handleSubmit}>
            <label htmlFor="username">e-mail:</label>
            <input
              type="email"
              id="username"
              ref={userRef}
              autoComplete="on"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
            />

            <label htmlFor="password">Lozinka:</label>
            <input
              type={!passVisible ? "password" : "text"}
              id="password"
              onChange={(e) => setPwd(e.target.value)}
              value={pwd}
              required
            />

            <div className="pass-visible">
              <label htmlFor="pass-vis">Pokaži lozinku</label>
              <input
                type="checkbox"
                name="pass-vis"
                id="pass-vis"
                className="pass-vis-color"
                onClick={passwordVisibility}
              />
            </div>

            <button type="submit" className="form-button">
              Pošalji
            </button>
          </form>
          <p className="treba-registracija">
            Niste registrovani?
            <br />
            <span className="line">
              {/*put router link here*/}
              <a href="/user-register">Napravite nalog</a>
            </span>
          </p>
        </section>
      )}
    </>
  );
};

export default UserLogIn;
