import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import Smestaj from "./components/pages/Smestaj";
import SmestajInfo from "./components/pages/SmestajInfo";
import Oglasi from "./components/pages/Oglasi";
import OglasInfo from "./components/pages/OglasInfo";
import OglasNovi from "./components/pages/OglasNovi";
import Restorani from "./components/pages/Restorani";
import RestoranInfo from "./components/pages/RestoranInfo";
import Sightseeing from "./components/pages/Sightseeing";
import Weather from "./components/pages/Weather";
import Info from "./components/pages/Info";
import InfoEdit from "./components/pages/InfoEdit";
import Main from "./components/pages/Main";
import NoPage from "./components/pages/NoPage";
import UserRegister from "./components/user/UserRegister";
import UserLogIn from "./components/user/UserLogIn";
import Admin from "./components/admin/Admin";

function App() {
  const [activeBtn, setActiveBtn] = useState({ activeButton: 1 });
  const handleActiveBtn = (data) => {
    setActiveBtn({ ...activeBtn, activeButton: data });
  };

  return (
    <BrowserRouter>
      <Header active={activeBtn.activeButton} />
      <div className="content-wrap">
        <Routes>
          <Route path="/" element={<Main func={handleActiveBtn} />} />
          <Route path="/oglasi" element={<Oglasi func={handleActiveBtn} />} />
          <Route
            path="/oglas-novi"
            element={<OglasNovi func={handleActiveBtn} />}
          />
          <Route
            path="/oglas-info"
            element={<OglasInfo func={handleActiveBtn} />}
          />
          <Route path="/smestaj" element={<Smestaj func={handleActiveBtn} />} />
          <Route
            path="/smestaj-info"
            element={<SmestajInfo func={handleActiveBtn} />}
          />
          <Route
            path="/restorani"
            element={<Restorani func={handleActiveBtn} />}
          />
          <Route
            path="/restoran-info"
            element={<RestoranInfo func={handleActiveBtn} />}
          />
          <Route
            path="/sightseeing"
            element={<Sightseeing func={handleActiveBtn} />}
          />
          <Route path="/weather" element={<Weather func={handleActiveBtn} />} />
          <Route path="/info" element={<Info func={handleActiveBtn} />} />
          <Route
            path="info-edit"
            element={<InfoEdit func={handleActiveBtn} />}
          />
          <Route path="*" element={<NoPage />} />
          <Route path="/user-register" element={<UserRegister />} />
          <Route path="/user-login" element={<UserLogIn />} />
          <Route path="/admin" element={<Admin />} />
          {/* <Route path="/admin/*" element={<Admin />} /> */}
        </Routes>
      </div>
      <div className="page-footer">
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
