import React, { useEffect } from "react";

const Restorani = ({ func }) => {
  useEffect(() => {
    func(4);
  }, []);

  return <>RESTORANI</>;
};

export default Restorani;
