import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import "./oglasi.scss";
import Reklama1 from "../reklame/Reklama1";
import Reklama2 from "../reklame/Reklama2";
import { BsTelephone } from "react-icons/bs";
import { CiLocationOn } from "react-icons/ci";
import { BsPerson } from "react-icons/bs";

const OglasInfo = ({ func }) => {
  useEffect(() => {
    func(2);
  }, []);

  const location = useLocation();
  const data = location.state;
  console.log(data);

  return (
    <div className="container-oglasi">
      <div className="reklama">
        <Reklama1 />
      </div>
      <div className="container cards infostrana">
        <h1>{data.title}</h1>
        {/* CAROUSEL */}
        <Carousel>
          {data.photos.map((item) => (
            <Carousel.Item>
              <>
                <img src={item} class="d-block w-100" alt="..."></img>
              </>
            </Carousel.Item>
          ))}
        </Carousel>
        {/* CAROUSEL */}
        <p className="paragraf">{data.text}</p>

        <p className="cena">Cena: {data.cena}</p>

        <div className="osoba-mesto">
          <div className="oglasivac">
            <BsPerson className="person" />
            <p>{data.name}</p>
          </div>
          <div className="mesto">
            <CiLocationOn className="lokacija" />
            <p>{data.mesto}</p>
          </div>
        </div>

        <div className="telefon">
          <BsTelephone className="znak-tel" />
          <span>
            <a href={`tel:${data.phone}`}>{data.phone}</a>
          </span>
        </div>
      </div>
      <div className="reklama">
        <Reklama2 />
      </div>
    </div>
  );
};

export default OglasInfo;
