import React, { useEffect } from "react";

const InfoEdit = ({ func }) => {
  useEffect(() => {
    func(6);
  }, []);

  return <>INFO EDIT</>;
};

export default InfoEdit;
