import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "./pages.scss";
import Reklama1 from "../reklame/Reklama1";
import Reklama2 from "../reklame/Reklama2";
import smestaj from "../../data/smestaj.json";
import oglasi from "../../data/oglasi.json";

const Main = ({ func }) => {
  const navigate = useNavigate();

  useEffect(() => {
    func(1);
  }, []);

  let datum;
  switch (new Date().getMonth()) {
    case 0:
      datum = "January";
      break;
    case 1:
      datum = "February";
      break;
    case 2:
      datum = "March";
      break;
    case 3:
      datum = "April";
      break;
    case 4:
      datum = "May";
      break;
    case 5:
      datum = "June";
      break;
    case 6:
      datum = "July";
      break;
    case 7:
      datum = "August";
      break;
    case 8:
      datum = "September";
      break;
    case 9:
      datum = "October";
      break;
    case 10:
      datum = "November";
      break;
    case 11:
      datum = "December";
  }

  const datum_oglas = new Date().getTime();

  return (
    <div className="cont">
      <div
        className="weather"
        onClick={() => {
          navigate("/weather");
        }}
      >
        <iframe
          onClick={() => {
            navigate("/weather");
          }}
          className="weather_card"
          id="cardWidget"
          title="Card widget"
          src="https://www.yr.no/en/content/2-785704/card.html"
        ></iframe>
        <div className="otvori-weather">
          <a href="/weather">Vremenska prognoza za više dana . . .</a>
        </div>
      </div>
      <div className="reklama">
        <Reklama1 />
      </div>
      {/* APARTMANI */}
      <div className="apartmani">
        {smestaj.length > 0 &&
          smestaj.map(
            (item) =>
              // Ako je mesec TRUE da ga prikaze
              item.month[datum] === true &&
              // Prikazuje samo PRIORITY = 1
              item.priority === 1 && (
                <Link to="/smestaj-info" state={item}>
                  {/* Span tag da bi Link sadrzao content */}
                  <span className="card" style={{ display: "block" }}>
                    <div class="apartman">
                      <img src={item.photos[0]} alt="..."></img>
                      <div className="divright">
                        <h5>{item.name}</h5>
                        <p>{item.location}</p>
                      </div>
                    </div>
                  </span>
                </Link>
              )
          )}
      </div>
      <div className="otvori-smestaj">
        <a href="/smestaj">Pogledaj ponudu smeštaja . . .</a>
      </div>
      {/* APARTMANI */}
      <div className="reklama">
        <Reklama2 />
      </div>
      {/* OGLASI */}
      <div className="oglasi">
        {oglasi.length > 0 &&
          oglasi.map(
            (item) =>
              // Ako je ( datum < date_end ) PRIKAZI OGLAS
              item.show_ad === true &&
              item.priority === 1 &&
              datum_oglas < new Date(item.date_end).getTime() && (
                <Link to="/oglas-info" state={item}>
                  {/* Span tag da bi Link sadrzao content */}
                  <span className="card" style={{ display: "block" }}>
                    <div class="oglas">
                      <img src={item.photos[0]} alt="..."></img>
                      <div className="divright">
                        <h5>{item.title}</h5>
                        <h6>{item.cena}</h6>
                        <p>{item.mesto}</p>
                      </div>
                    </div>
                  </span>
                </Link>
              )
          )}
      </div>
      <div className="otvori-oglase">
        <a href="/oglasi">Pogledaj oglase . . .</a>
      </div>
      {/* OGLASI */}
      <div className="reklama">
        <Reklama2 />
      </div>
      <div className="restorani">
        <div className="restoran">RESTORAN 1</div>
        <div className="restoran">RESTORAN 2</div>
        <div className="restoran">RESTORAN 3</div>
        {/* <div className="restoran">RESTORAN 4</div>
        <div className="restoran">RESTORAN 5</div> */}
      </div>
      <div className="otvori-restorani">
        <a href="/restorani">Pogledaj više . . .</a>
      </div>
      {/* <div className="reklama">
        <Reklama2 />
      </div> */}
      {/* PROSTOR ZA VESTI */}
      {/* <div className="vesti">
        <div className="vest">VEST 1</div>
        <div className="vest">VEST 2</div>
        <div className="vest">VEST 3</div> */}
      {/* <div className="vest">VEST 4</div>
        <div className="vest">VEST 5</div> */}
      {/* <div className="otvori-info">
          <a href="/info">Pogledaj više . . .</a>
        </div>
      </div> */}
      <div className="reklama">
        <Reklama2 />
      </div>
    </div>
  );
};

export default Main;
