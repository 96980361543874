import "./footer.scss";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer">Volim Sokobanju 2023</div>
    </div>
  );
};

export default Footer;
