import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./smestaj.scss";
import smestaj from "../../data/smestaj.json";
import "bootstrap/dist/css/bootstrap.min.css";
import Reklama1 from "../reklame/Reklama1";
import Reklama2 from "../reklame/Reklama2";

const Smestaj = ({ func }) => {
  useEffect(() => {
    func(3);
  }, []);

  let datum;
  switch (new Date().getMonth()) {
    case 0:
      datum = "January";
      break;
    case 1:
      datum = "February";
      break;
    case 2:
      datum = "March";
      break;
    case 3:
      datum = "April";
      break;
    case 4:
      datum = "May";
      break;
    case 5:
      datum = "June";
      break;
    case 6:
      datum = "July";
      break;
    case 7:
      datum = "August";
      break;
    case 8:
      datum = "September";
      break;
    case 9:
      datum = "October";
      break;
    case 10:
      datum = "November";
      break;
    case 11:
      datum = "December";
  }

  // console.log(datum);
  // console.log(smestaj);
  // console.log(smestaj[0].month.April);
  // console.log(smestaj[1].month[datum]);

  return (
    <div className="container-smestaj">
      <div className="reklama">
        <Reklama1 />
      </div>
      <div className="container cards">
        {/* <div className="row justify-content-center">
          <div className="col-12"> */}
        {smestaj.length > 0 &&
          smestaj.map(
            (item) =>
              // Ako je mesec TRUE da ga prikaze
              item.month[datum] === true && (
                <div class="card hoverable kartica">
                  <>
                    <img
                      src={item.photos[0]}
                      class="card-img-top"
                      alt="..."
                    ></img>
                  </>
                  <div class="card-body">
                    <h5 class="card-title">{item.name}</h5>
                    <p class="card-text">{item.location}</p>
                    <Link to="/smestaj-info" state={item}>
                      Prikaži više
                    </Link>
                  </div>
                </div>
              )
          )}
        {/* </div>
        </div> */}
      </div>
      <div className="reklama">
        <Reklama2 />
      </div>
    </div>
  );
};

export default Smestaj;
