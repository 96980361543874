import React, { useEffect } from "react";

const Sightseeing = ({ func }) => {
  useEffect(() => {
    func(7);
  }, []);

  return <>ŠTA VIDETI?</>;
};

export default Sightseeing;
